/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
  height: 100%;
}

:root {
  --quenchPink: #e83694;
  // --hover: #eeeded;
  --lineBreaks: #eeeded;
  // --dischemGreen: #35c27e;
}

.true{
  color: green;
}

.false{
  color: red;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

mat-label{
  font-size: 12px;
}

.mat-horizontal-stepper-header-container{
  display: none !important;
}

.cdk-overlay-pane{
  max-width: 92vw !important;
}
// input{
//   font-size: 14px !important;
// }

.isBold {
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 5px;
}

// if data is not specified or missing
.empty{
  color: grey;
}

.no-data{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 190px);
  justify-content: center;
  text-align: center;
  
  img{
    height: 100px;
  }
}

.active{
  color: green;
}

.inactive{
  color: red;
}

.field {
  width: 99%;
  font-size: 12px;
}

.save {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.mat-horizontal-content-container{
  padding: 0 !important;
}



// ==============
//   DASHBOARD
// ==============

.dashboard{
  // background: yellow;
  position: relative;
  top: 115px;
  overflow: hidden;
}


// ==============
//     ALL
// ==============

.mat-expansion-panel-body{
  padding: unset !important;
}

.back{
  font-size: 13px;
  text-transform: capitalize;
  display: flex;
  align-items: baseline;
  color: #999;
  width: max-content;

  mat-icon{
    font-size: 16px;
    position: relative;
    top: 3px;
  }

  p{
    margin: 0;
  }
}

.mat-progress-spinner circle, .mat-spinner circle{
  stroke: var(--quenchPink);
}

th{
  font-size: 13px;
  font-weight: 400;
  height: 48px;
  border-bottom: 1px solid #d7d6d6;
}

.stores {
  // position: absolute;
  // top: -15px;
  // left: 0;
  // right: 0;
  // padding: 0px 40px;

  .tables {
    display: flex;
    align-items: first baseline;
    margin-top: 50px;
    margin-bottom: 20px;

    .search {
      display: flex;
      justify-content: space-between;

      #form{
        margin: -5px;
      }

  }

    .paginate {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      width: 100%;
    }

      .pager{
        position: fixed;
        bottom: 0;
        width: 48%;
        display: flex;
        justify-content: center;
        height: 30px;
        background-color: #fff;


      .page {
        display: flex;
        align-items: center;
        font-size: 13px;

        div {
          padding: 0 14px;
        }

        mat-icon {
          cursor: pointer;
        } 
      }
    }
  }
}


// date range height
.mat-datepicker-content .mat-calendar{
  width: 300px;
  height: max-content !important;
  // border-radius: 20px;
  // overflow: hidden;
  // overflow: scroll;
}


.mat-datepicker-content .mat-calendar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.mat-datepicker-content .mat-calendar{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.mat-date-range-input-container{
  line-height: 18px;
}

@media only screen and (max-width: 600px){
  .stores{
    padding: 0 20px;
  }
}


// ==============
//     SILOS
// ==============

.silos {
  display: flex;
  position: relative;
  z-index: 10;

  div {
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 10px;
  }

  button {
    position: absolute;
    right: 20px;
  }
}

// ==============
//    TABLES
// ==============

.mat-header-row{
  height: 45px;
  background-color: #eeeded;
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type{
  position: relative;
  left: 8px;
}

// take out when search done

.add {
  position: fixed;
  right: 40px;
  top: 0;
  z-index: 1000;

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    color: var(--quenchPink);
  }

  .mat-form-field-appearance-legacy .mat-form-field-ripple {
    color: var(--quenchPink);
  }
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell{
  border-bottom-width: 0.8px;
}

.search {
  position: fixed;
  right: 100px;
  top: 0;
  z-index: 1000;
  background: #f6f5f5;
  width: 320px;
  height: 40px;
  border-radius: 40px;
  top: 8px;
  overflow: hidden;

  .search-icon{
    position: absolute;
    top: 10px;
    cursor: pointer;
    color: gray;
    font-size: 20px;
    transition: left .1s ease;
  }

  .clear-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 20px;
    color: gray;
  }

  input{
    position: absolute;
    top: 12px;
    width: 230px;
    background: #f6f5f5;
    border: none;
    font-size: 13px;
    outline: none;
    transition: left .1s ease;
  }

  .mat-input-element{
    caret-color: red;
  }

  ::placeholder{
    opacity: 1;
    color: rgb(105, 103, 103);
  }


  .mat-form-field-appearance-legacy .mat-form-field-underline {
   color: var(--quenchPink);
   position: relative;
   top: -12px;
   display: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-ripple {
    color: var(--quenchPink);
  }
}

table {
  border-collapse: collapse;
  width: calc(100% - 20px);
  margin: 15px 0;

  th {
    text-align: left;
    text-transform: capitalize;
  }

  td {
    // border-bottom: 1px solid #eee;
    font-size: 13px;
  }

  #img {
    text-align: center;
    padding: 0;
    width: 80px;
    position: relative;
    left: -15px;

    #prod-img {
      height: 48px;
    }
  }
}

th.mat-header-cell{
  font-size: 12px;
  color: #000;
}

.mat-cell, .mat-footer-cell{
  font-size: 12px;
  max-width: 160px;
  word-wrap: break-word;


  p{
    width: 85%;
  }
}


#create{
  position: absolute;
  left: 90px;
}  

#create-expand{
  position: absolute;
  left: 250px;
}

 .mat-flat-button{
  line-height: 30px !important;
  font-family: 'Poppins', sans-serif;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 15px!important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 0 !important;
}


// ==============
//     FORMS
// ==============

.double-form {
  display: flex;
  width: 810px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.form {
  display: flex;
  justify-content: center;

  .split {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 10px;

    #form-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .btn {
    display: flex;
    justify-content: center;
  }
}

// select search

.select-search{
  height: 40px;
  width: 100%;
  border: none;
  padding: 0 15px;
}

// newest create forms

.create-card{
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.08);
  border-radius: 8px;
  width: 800px;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;
  height: 450px;
  overflow: hidden;
  padding-bottom: 40px;
  overflow-x: hidden;
  margin-bottom: 30px;

  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1.2px solid grey;
    margin-bottom: 15px;

    h3{
      font-size: 20px;
      text-transform: capitalize;
      font-weight: 400;
      margin: 10px 0;
    }

    mat-icon{
      cursor: pointer;
      background: #d0d0d0;
      border-radius: 20px;
      padding: 3px;
    }
  }

  .mat-radio-button {
    font-size: 12px;
    margin: 15px 0;
    margin-right: 25px;
  }

  .first-form{
    height: calc(100% - 30px);
    position: relative;
    transition: left .4s ease;

    .field{
      width: 100%;
      font-size: 12px;
    }

    mat-form-field{
      margin: 0 1px;
      font-size: 12px;
      width: 100%;
    }
  }

  .btn{
      width: 100%;
      position: absolute;
      bottom: 0;
      text-align: end;
  
      .skip{
        background: #d9d9d9;
        color: #000;
        width: 100px;
      }
  
      button{
        width: 150px;
        right: 10px;
        margin: 10px;
      }
    }
  }

// forms responsiveness

@media only screen and (max-width: 900px){

  .back{
    margin-left: 10px;
    margin-bottom: 30px;
  }

  .create-card{
    width: calc(100vw - 40px);

    .form{
      display: block;
    }
  }

}


// ==============
//    SHADE
// ==============

.shade {
  display: flex;
  justify-content: center;

  .mat-progress-spinner {
    top: 200px;
  }
}

#shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.32);
  opacity: 1;
  z-index: 100000;
}

// ==============
//    GENERAL
// ==============

.button {
  cursor: pointer;
  background-color: var(--quenchPink);
  color: #fff;
}

.action {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// =================
//       TABS
// =================

.tabs {
  display: flex;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  text-transform: uppercase!important;
  .selectedTab {

    #tab-name {
      color: var(--quenchPink);
      // text-decoration: underline;
      border-bottom: 2px solid var(--quenchPink);
      // border-bottom: #e83694;
    }
    
  }

  div {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 20px;
  }
}

// select panel overflow hide 
#mat-select-0-panel{
  overflow-x: hidden;
}

.mat-chip-list-wrapper{
  justify-content: center;
}
// =================
//      DETAIL
// =================

.details {
  display: flex;
  position: relative;
  margin-top: 30px;

  div {
    flex: 1;

    #stores {
      position: relative;
      margin-top: -15px;
    }

    .mat-expansion-panel {
      box-shadow: none;
      max-width: 450px;

      .mat-expansion-panel-header {
        padding: 0;
      }

      .mat-expansion-panel-body {
        text-align: center;
      }
    }
  }
}

// =================
//   DRAG AND DROP
// =================

.draglist-box {
  display: flex;
  align-items: center;
  height: 48px;

  div {

    img{
      height: 25px;
    }
  }
  border-bottom: 1px solid #eee;
  background-color: white;
}

.draglist-box:active {
  background-color: #f7f7f7;
  background: red;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

  height: 48px;
  background-color: white;


  .sort{
    width: 50px;
  }

  .icon{
    width: 60px;
  }

  .slider{
    display: none;
  }

  .del{
    display: none;
  }

  .edit{
    display: none;
  }
}

.retailer-search{
  height: 40px;
  width: 100%;
  border: none;
  padding: 0 15px;
}

// =============
//    DIALOG
// =============
.mat-dialog-container{
  position: relative;
  padding: 5px 15px !important;
}

#dialog{
  display: flex;
  justify-content: center;

  .dialog {
    position: fixed;
    z-index: 1000000;
    text-align: center;
    width: 250px;
    margin: auto;
    top: 230px;
    background: #fff;
    padding: 10px;
    border-radius: 4px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 14px;
        font-weight: 400;
      }

      mat-icon {
        position: absolute;
        right: 0;
        font-size: 20px;
        top: 5px;
      }
    }
  }
}

.cdk-overlay-container, .cdk-overlay-pane {
  z-index: 9999999 !important;
}

.pac-container {
  z-index: 1000000000;
}

.mat-progress-spinner circle, .mat-spinner circle{
  stroke: var(--quenchPink);
}

.mat-progress-spinner svg{
  height: 25px !important;
}


.mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode="indeterminate"]{
animation: none !important;
}

.mat-progress-spinner {
height: 30px;
}

.spinner{
  display: flex;
  flex-direction: column;
  justify-content: center;

  .mat-progress-spinner svg{
    height: 50px !important;
  }
}

.mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode="indeterminate"]{
  animation: none;
}

// =================
//      PAGER
// =================

#pager {
  background-color: #fff;
  height: 50px;
  position: fixed;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  z-index: 1000;
  padding: 0 10px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  border-top: 1px solid rgba(0,0,0,.12);

  .page {
    display: flex;
    align-items: center;

    div {
      padding: 0 20px;
    }
  }

  mat-icon,
  .limit {
    cursor: pointer;
  }
}

mat-expansion-panel-body{
  padding: 0;
}

.subMenuMobile{
  display: none;
}

// =================
//   DELETE DIALOG
// =================

.delete {
  position: fixed;
  z-index: 1000000;
  text-align: center;
  width: 250px;
  margin: auto;
  top: 230px;
  background: #fff;
  padding: 10px;
  border-radius: 4px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      mat-icon {
        position: absolute;
        right: 0;
        font-size: 20px;
        top: 5px;
      }
    }
  }



  .mat-progress-spinner svg{
      height: 50px !important;
    }

    .spin{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 75vh;
    }


  .mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode="indeterminate"]{
    animation: none;
  }

  .mat-progress-spinner {
    height: 30px;
  }


// =================
//      MOBILE
// =================

.mobile {
  display: none;
}

.agm-map-container-inner.sebm-google-map-container-inner{
  border-radius: 4px;
}

.mobileSearch{
  display: none;
}

.mat-dialog-container {
  // width: 1600px ;

  #close{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    }
}


@media only screen and (max-width: 1100px) {
  // table hide
  table {
    display: none;
  }

  // expansion panel for mobile

  .mobile{
    display: block;
    position: relative;
    // top: 20px;
    padding: 15px;
    height: calc(100vh - 220px);
    // overflow: auto;
    background-color: #fff;

    mat-expansion-panel{
      margin: 10px 8px;
      // padding: 7px 0;

      .view-button{
        text-align: end;
        margin: 10px 0;
      }

      mat-panel-title{
        font-weight: 401;
        border-bottom: 1px solid grey;
        padding: 7px 0;
      }

      .flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid grey;
        min-height: 48px;

        mat-title{
          flex: 1;
        }

        p{
          flex: 1;
        }
      }
    }
  }

  // search field
  .search{
    display: none;
  }

  .details{
    display: block;
  }

  #pager {
    display: none;
  }
}

// product search on double tables

.product-search{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


@media only screen and (max-width: 750px) {
  #name{
    width: 100px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  // take out .add after .search done

  .add{
  display: none;
  }

  .mobileSearch{
    display: block;
    cursor: pointer;
    background: red;

    .mobileSearchInput{
      position: fixed;
      left: 90px;
      background: #f7f7f7;
      z-index: 10000;
      transition: top 0.3s;
      height: 45px;
    }

    #mobileSearchIcon{
      position: fixed;
      top: 15px;
      right: 15px;
      z-index: 10000;
    }
  }


  .siloMobile{
    display: flex;
  }
  
  .subMenuMobile{
    display: flex;
  }

  #mobile{

  #mat-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

    p{
      font-size: 12px;
    }
  }
}


// edit box for details

.edit{
  width: 800px;
  height: 80%;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  position: fixed;
  top:0;
  left: 50%;
  transform: translate(-50%, 10%);
  z-index: 100000;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  overflow: hidden;

  .heading{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1.2px solid grey;

    h4{
      margin: 10px;
    }

    mat-icon{
      position: absolute;
      right: 0;
      cursor: pointer;
      background: #d0d0d0;
      border-radius: 20px;
      padding: 3px;
    }
  }

  .edit-fields{
    // background-color: green;
    overflow: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px 0;
    height: calc(100% - 100px);    

    .mat-form-field{
      margin: 8px;
      font-size: 12px;
      width: calc(40% - 15px);
    }

    .field{
      width: calc(80% - 15px);
    }
  }

  .save{
    background-color: #f9f9f9;
    // background-color: red;
    text-align: right;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 10px 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    // border-top: 1.2px solid grey;

    button{
      margin: 0 8px;
      width: 100px;
    }

  }

}

.cancel{
  background: #d0d0d0;
  color: black;
}


// ==============
//      CARTS
// ==============

tr:hover{
  position: relative;
  background: unset;
  background-color: rgb(232, 231, 231);
}

.order-time{
  margin-top: -10px;
  margin-bottom: 11px;
  font-size: 12px;
  color: #919191;
  font-weight: 399;
}

.status{
  width: 80px !important;
  padding: 8px;
  border-radius: 8px;
  height: 8px;
  line-height: 8px;
  text-align: center;
}

#placed-status{
  background-color: #eae7d1;
  color: #b59720;
}

#open-status{
  background-color: #dce7f7;
  color: blue;
}

#paid-status{
  background: #ccf7cc;
  color: green;
}

#dispatched-status{
  background: #ddddf4;
  color: purple;
}

.dispatched{
  background-color: #f3eff6;
}

#cancelled-status{
  background-color: #fbe9e9;
  color: red;
}

#completed-status{
  background-color: #e1ca9e;
  color: #a45b1b;
}

.cart-number-mobile{
  position: absolute;
  right: 15px;
  font-weight: 400;
}


// ngxSpinner fit in box

